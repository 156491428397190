import { graphql } from "gatsby"
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"
import FindDreamJob from "../components/careers/finddreamjob"
import BenefitCorp from "../components/education/benefitcorp"
import ButtonApplyNow from "../components/layout/buttonapplynow"
import DarkHeader from "../components/layout/darkheader"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import "./education.scss"
import "./mainpage.scss"

const EducationPage = () => {
    const { i18n } = useI18next()
    const { t } = useTranslation()
    const pageTitle = t("title")
    const pageDescription = t("Join our Team, Find Your Dream Job")
    const lang = 'EN'
    const meta = []
    const breadcrumbMap = []
    const title1 = t("Technology Centers");
    const title2 = t("The only school that pays you to attend");
    const sectionClassName = "section-education-header";

    return (
        <>
            <Layout breadcrumbMap={breadcrumbMap} headerTransparent={true} lang={i18n.resolvedLanguage}>
                <div className="max-container-pages">
                    <Seo title={pageTitle} description={pageDescription} lang={i18n.resolvedLanguage} meta={meta} />
                    <DarkHeader
                        title1={title1}
                        title2={title2}
                        sectionClassName={sectionClassName}
                        //onAccept={() => handleShow('xxl-down')}
                        callButton={<ButtonApplyNow title={[t("Apply to Join"), t("BTS Education Program")]} formVariant="join" job={t("Technology Centers")} arrow={false} >{t("Join Now")}</ButtonApplyNow>}
                    >
                        <p>
                            {t("Our internship program was born 7 years ago")}
                        </p>
                        <p className="last">
                            {t("The engineers who graduated from our internship program rapidly became top notch developers")}
                        </p>
                    </DarkHeader>
                    <BenefitCorp></BenefitCorp>
                    <FindDreamJob></FindDreamJob>

                </div>
            </Layout>
        </>
    )
}

export default EducationPage
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "education"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
