/**
 * PROPS
 * location: key of the location to show
 */
import "./benefitcorp.scss"

import * as React from "react"
import { Row, Col } from "react-bootstrap"
import { useTranslation } from "gatsby-plugin-react-i18next"
const BenefitCorp = ({ lang = "EN" }) => {
    const { t,i18n } = useTranslation()
    return (
        <>
            <section id="goContent" className={i18n.language.toLowerCase() === "es" ? "section-benefit-corp es-bg-benefit" : "section-benefit-corp"}>
                <Row>
                    <Col className="col-text" lg={6}>
                        <div className="wrapper-text">
                            <h3 className="header-square green md-large">
                                {t("A benefit corporation, we were also looking for ideas to make a stronger social impact on our local communities")}
                            </h3>
                            <p>
                                {t("Hence the idea of creating technology centers that would welcome students who only have a high school degree")}
                            </p>
                            <p className="p-short">
                                <em>
                                    {t("It is our goal to leverage this first experience to open more technology centers in Latin America and in the USA")}
                                </em>
                            </p>
                        </div>
                    </Col>
                    <Col className={i18n.language.toLowerCase() === "es" ? "col-img es-bg" : "col-img"} lg={6}>

                    </Col>
                </Row>
            </section>
        </>
    )
}

export default BenefitCorp
